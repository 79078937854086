<template>
  <CFooter>
    <div>
      <!-- <a href="https://coreui.io" target="_blank">CoreUI</a> -->
      
    </div>
    <div class="ms-auto">
      <!-- <span class="me-1" target="_blank">Powered by</span>
      <a href="https://coreui.io/vue">CoreUI for Vue</a> -->
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} Embagga App.</span
      >
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
